import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { commonColors } from '../commonColors';
import VerticalLinearStepper from '../components/Stepper';
import { localStorageKeys } from '../localStorageKeys';
import { HeaderBar } from '../components/HeaderBar';

export const Describe = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [answers, setAnswers] = useState({
    [localStorageKeys.summary]: localStorage.getItem(localStorageKeys.summary) || '',
    [localStorageKeys.audience]: localStorage.getItem(localStorageKeys.audience) || '',
    [localStorageKeys.audienceGoals]: localStorage.getItem(localStorageKeys.audienceGoals) || ''
  });

  const isUserContextFilled = Object.values(answers).every((answer) => answer !== '');

  const handleUserInput = (localStorageKey: string, value: string) => {
    localStorage.setItem(localStorageKey, value);
    setAnswers((prevAnswers) => ({ ...prevAnswers, [localStorageKey]: value }));
  };

  const handleNextButton = () => {
    if (isUserContextFilled) {
      navigate('/insights');
    }
  };

  const reset = () => {
    localStorage.clear();
    setAnswers({
      [localStorageKeys.summary]: '',
      [localStorageKeys.audience]: '',
      [localStorageKeys.audienceGoals]: ''
    });
  };

  return (
    <>
      <HeaderBar />
      <Grid
        container
        padding={isSmallScreen ? '1rem 0rem' : '2rem 5rem'}
        justifyContent={isSmallScreen ? 'center' : 'left-align'}
        bgcolor={commonColors.lightBlue}>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'stretch'
        }}>
        <Grid container item justifyContent="center" xs={12} sm={10} md={8}>
          <VerticalLinearStepper activeStep={0} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          sx={{
            backgroundColor: 'white',
            padding: '2rem',
            height: '60vh',
            overflowY: 'auto',
            marginTop: '1rem'
          }}>
          <Grid item xs={12}>
            <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
              Describe Your Audience
            </Typography>
          </Grid>
          <Grid item paddingTop="2rem">
            <Grid item>
              <Typography variant={'body1'}>Summarize your product or service</Typography>
            </Grid>
            <Grid item>
              <Typography variant={'caption'}>
                Write a sentence beginning with the words "How to ..."
              </Typography>
            </Grid>
            <Grid item paddingTop="0.5rem">
              <TextField
                inputProps={{ 'data-testid': `user-input-summary` }}
                placeholder="ex. How to become a certified life coach"
                value={localStorage.getItem(localStorageKeys.summary) || ''}
                multiline
                fullWidth
                variant={'outlined'}
                onChange={(val) => {
                  handleUserInput(localStorageKeys.summary, val.target.value);
                }}></TextField>
            </Grid>
          </Grid>
          <Grid item paddingTop="2rem">
            <Grid item>
              <Typography variant={'body1'}>Describe your audience</Typography>
            </Grid>
            <Grid item>
              <Typography variant={'caption'}>
                Write a single, short phrase that describes the audience and what they want to
                accomplish
              </Typography>
            </Grid>
            <Grid item paddingTop="0.5rem">
              <TextField
                inputProps={{ 'data-testid': `user-input-audience` }}
                placeholder="ex. Women over 35 who want to start a business as a life coach"
                value={localStorage.getItem(localStorageKeys.audience) || ''}
                multiline
                fullWidth
                variant={'outlined'}
                onChange={(val) => {
                  handleUserInput(localStorageKeys.audience, val.target.value);
                }}></TextField>
            </Grid>
          </Grid>
          <Grid item paddingTop="2rem">
            <Grid item>
              <Typography variant={'body1'}>Explain what your audience wants to achieve</Typography>
            </Grid>
            <Grid item>
              <Typography variant={'caption'}>
                Articulate your audience’s goal. What does your audience want to achieve?
              </Typography>
            </Grid>
            <Grid item paddingTop="0.5rem">
              <TextField
                inputProps={{ 'data-testid': `user-input-audienceGoals` }}
                placeholder="ex. Start a successful business as a life coach to gain time freedom and career satisfaction"
                value={localStorage.getItem(localStorageKeys.audienceGoals) || ''}
                multiline
                fullWidth
                variant={'outlined'}
                onChange={(val) => {
                  handleUserInput(localStorageKeys.audienceGoals, val.target.value);
                }}></TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              padding={isSmallScreen ? '1rem 1rem' : '1rem 5rem'}>
              <Button
                data-testid="reset-button"
                onClick={reset}
                variant="outlined"
                sx={{ minWidth: '4rem' }}>
                CLEAR AND RESTART
              </Button>

              <Button
                data-testid="next-button"
                onClick={handleNextButton}
                variant="contained"
                disabled={!isUserContextFilled}>
                NEXT
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
