export const autopopulatedRevisionChoices: [string, string][] = [
    ["More straightforward", "Make it more straightforward."],
    ["More direct", "Make the tone more direct."],
    ["Less formal", "Make it less formal."],
    ["More conversational", "Make it more conversational."],
    ["Witty but factual", "Rewrite it in a witty but factual style."],
    ["Less generic", "Make it less generic, more specific, and uncommon."],
    ["Tone it down", "The tone is too excited. Tone it down."],
    ["Less salesy", "Rewrite it in a way that is less salesy."],
    ["More details", "Drill down and give more details with a few more sentences but be sure to keep it useful and engaging."],
    ["Less sentences", "Rewrite it with less sentences."],
    ["More useful", "This is too ordinary. Make it more surprising and useful."]
]

