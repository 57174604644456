export const generateInsights = async (
  summary: string,
  audience: string,
  audienceGoals: string
) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-insights`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      summary,
      audience,
      audienceGoals
    })
  });
  return res.body?.pipeThrough(new TextDecoderStream()).getReader();
};

export const reviseInsight = async (insight: string, revision: string) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/revise-insight`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      insight,
      revision
    })
  });
  return res.body?.pipeThrough(new TextDecoderStream()).getReader();
};

export const generateStories = async (
  summary: string,
  audience: string,
  audienceGoals: string,
  insight: string
) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-stories`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      summary,
      audience,
      audienceGoals,
      insight
    })
  });
  return res.body?.pipeThrough(new TextDecoderStream()).getReader();
};

export const reviseStory = async (story: string, revision: string) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/revise-story`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      story,
      revision
    })
  });
  return res.body?.pipeThrough(new TextDecoderStream()).getReader();
};

export const generateSubjectLines = async (story: string) => {
  const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}/generate-subject-lines`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      story
    })
  });
  return res.body?.pipeThrough(new TextDecoderStream()).getReader();
};
