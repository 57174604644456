import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Describe } from './pages/Describe';
import { Insights } from './pages/Insights';
import { Story } from './pages/Story';
import { Subject } from './pages/Subject';
import { Confirmation } from './pages/Confirmation';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Describe />
  },
  {
    path: '/describe',
    element: <Describe />
  },
  {
    path: '/insights',
    element: <Insights />
  },
  {
    path: '/story',
    element: <Story />
  },
  {
    path: '/subject',
    element: <Subject />
  },
  {
    path: '/confirmation',
    element: <Confirmation />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<RouterProvider router={router} />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
