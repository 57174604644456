import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Alert, AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { generateStories } from '../services/networkRequests';
import { localStorageKeys } from '../localStorageKeys';
import { useNavigate } from 'react-router-dom';
import { parseResponseValue } from '../services/parseResponseValue';
import { GeneratedResponses } from './GeneratedResponses';

export const GenerateStories: React.FC<{ setShowReview: (showReview: boolean) => void }> = ({
  setShowReview
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [firstStory, setFirstStory] = useState('');
  const [secondStory, setSecondStory] = useState('');
  const [thirdStory, setThirdStory] = useState('');
  const [fourthStory, setFourthStory] = useState('');
  const [didUserSelect, setDidUserSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const setStories = async () => {
    setLoading(true);
    const summary = localStorage.getItem(localStorageKeys.summary) || '';
    const audience = localStorage.getItem(localStorageKeys.audience) || '';
    const audienceGoals = localStorage.getItem(localStorageKeys.audienceGoals) || '';
    const insight = localStorage.getItem(localStorageKeys.selectedInsight) || '';

    try {
      const responseReader = await generateStories(summary, audience, audienceGoals, insight);
      setLoading(false);
      if (responseReader === undefined) {
        console.error('Something went wrong when corresponding with the AI');
        setError(true);
        return;
      }
      let index = -1;
      while (true) {
        const { value, done } = await responseReader.read();
        if (done) {
          break;
        }

        try {
          index = parseResponseValue(index, value, [
            setFirstStory,
            setSecondStory,
            setThirdStory,
            setFourthStory
          ]);
        } catch (error) {
          setError(true);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setStories();
  }, []);

  const handleNextButton = () => {
    setShowReview(true);
  };

  const reset = () => {
    localStorage.clear();
    navigate('/describe');
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        sx={{
          backgroundColor: 'white',
          padding: '2rem',
          height: '60vh',
          overflowY: 'auto',
          marginTop: '1rem'
        }}>
        <Grid item xs={12}>
          <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
            Select a Story
          </Typography>
        </Grid>
        <Grid item xs={12} paddingTop={'1rem'}>
          <Typography variant={'body1'}>
            We’ve generated a list of stories based on the insight you selected. Select one.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'caption'}>
            Generated stories may contain inaccurate information. Verify details.
          </Typography>
        </Grid>
        {loading && !error && (
          <Grid item xs={12} sx={{ marginTop: '2rem' }}>
            <CircularProgress />
          </Grid>
        )}
        {!loading && !error && (
          <GeneratedResponses
            generatedResponses={[firstStory, secondStory, thirdStory, fourthStory]}
            selectionKey={localStorageKeys.selectedStory}
            setDidUserSelect={setDidUserSelect}></GeneratedResponses>
        )}
        {error && (
            <Grid item xs={12} paddingTop={'2rem'}>
              <Alert severity="warning">
                <AlertTitle>We couldn't generate stories.</AlertTitle>
                Because of a technical issue, we couldn't get an answer from the AI. Try refreshing
                your page. If the issue continues, click the "Clear and Restart" button.
              </Alert>
            </Grid>
        )}
      </Grid>
      <Grid container item>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <Box
            display="flex"
            justifyContent="space-between"
            padding={isSmallScreen ? '1rem 1rem' : '1rem 5rem'}>
            <Button
              data-testid="reset-button"
              onClick={reset}
              variant="outlined"
              sx={{ minWidth: '4rem' }}>
              CLEAR AND RESTART
            </Button>
            <Button
              data-testid="next-button"
              disabled={!didUserSelect}
              onClick={handleNextButton}
              variant="contained">
              NEXT
            </Button>
          </Box>
        </Paper>
      </Grid>
    </>
  );
};
