import * as React from 'react';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { commonColors } from '../commonColors';
import VerticalLinearStepper from '../components/Stepper';
import { HeaderBar } from '../components/HeaderBar';
import { GenerateInsights } from '../components/GenerateInsights';
import { ReviewInsights } from '../components/ReviewInsights';
import {useState} from "react";

export const Insights = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [showReview, setShowReview] = useState(false);

  return (
    <>
      <HeaderBar />
      <Grid
        container
        padding={isSmallScreen ? '1rem 0rem' : '2rem 5rem'}
        justifyContent={isSmallScreen ? 'center' : 'left-align'}
        bgcolor={commonColors.lightBlue}>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'stretch'
        }}>
        <Grid container item justifyContent="center" xs={12} sm={10} md={8}>
          <VerticalLinearStepper activeStep={1} />
        </Grid>
        {!showReview && <GenerateInsights setShowReview={setShowReview} />}
        {showReview && <ReviewInsights />}
      </Grid>
    </>
  );
};
