import * as React from 'react';
import {useState} from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    Grid,
    Paper,
    RadioGroup,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {RadioSelect} from './RadioSelect';
import {GeneratedResponses} from './GeneratedResponses';
import {generateInsights} from '../services/networkRequests';
import {useNavigate} from 'react-router-dom';
import {localStorageKeys} from '../localStorageKeys';
import {parseResponseValue} from '../services/parseResponseValue';

export const GenerateInsights: React.FC<{ setShowReview: (showReview: boolean) => void }> = ({
                                                                                                 setShowReview
                                                                                             }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isGeneratedInsight, setIsGeneratedInsight] = useState<boolean>(false);
    const [userInsight, setUserInsight] = useState(
        localStorage.getItem(localStorageKeys.selectedInsight) || ''
    );
    const [firstInsight, setFirstInsight] = useState('');
    const [secondInsight, setSecondInsight] = useState('');
    const [thirdInsight, setThirdInsight] = useState('');
    const [fourthInsight, setFourthInsight] = useState('');
    const [didUserSelect, setDidUserSelect] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const isInsightSelected =
        (!isGeneratedInsight && userInsight !== '') || (isGeneratedInsight && didUserSelect);

    const handleInsight = async (newStatus: boolean) => {
        setError(false);
        setIsGeneratedInsight(newStatus);
        if (!newStatus) {
            return;
        }
        setLoading(true);
        const summary = localStorage.getItem(localStorageKeys.summary) || '';
        const audience = localStorage.getItem(localStorageKeys.audience) || '';
        const audienceGoals = localStorage.getItem(localStorageKeys.audienceGoals) || '';
        try {
            const responseReader = await generateInsights(summary, audience, audienceGoals);
            setLoading(false);
            if (responseReader === undefined) {
                console.error('Something went wrong when corresponding with the AI');
                setError(true);
                return;
            }
            let index = -1;
            while (true) {
                const {value, done} = await responseReader.read();
                if (done) {
                    break;
                }

                try {
                    index = parseResponseValue(index, value, [
                        setFirstInsight,
                        setSecondInsight,
                        setThirdInsight,
                        setFourthInsight
                    ]);
                } catch (error) {
                    setError(true);
                    setLoading(false);
                }
            }
        } catch (error) {
            console.log(error);
            setError(true);
            setLoading(false);
        }
    };

    const handleNextButton = () => {
        if (!isGeneratedInsight) {
            localStorage.setItem(localStorageKeys.selectedInsight, userInsight);
        }
        if (isGeneratedInsight) {
            setShowReview(true);
        } else {
            navigate('/story');
        }
    };

    const reset = () => {
        localStorage.clear();
        navigate('/describe');
    };

    return (
        <>
            <Grid
                item
                xs={12}
                sm={10}
                md={8}
                sx={{
                    backgroundColor: 'white',
                    padding: '2rem',
                    height: '60vh',
                    overflowY: 'auto',
                    marginTop: '1rem'
                }}>
                <Grid item xs={12}>
                    <Typography variant={'h6'} sx={{fontWeight: 'bold'}}>
                        Generate Insights
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item xs={12} paddingTop={'1rem'}>
                        <Typography variant={'body1'}>
                            Do you want to manually write a list of insights or use AI to automatically generate a
                            list?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} paddingTop={'0.75rem'}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="no"
                            name="radio-buttons-group"
                            value={isGeneratedInsight ? 'yes' : 'no'}
                            onChange={() => handleInsight(!isGeneratedInsight)}>
                            <RadioSelect
                                data-testid={"ai-insight-no"}
                                isSelected={!isGeneratedInsight}
                                value={'no'}
                                label={'I want to write my own insights'}></RadioSelect>
                            <RadioSelect
                                data-testid={"ai-insight-yes"}
                                isSelected={isGeneratedInsight}
                                value={'yes'}
                                label={'I want to use AI to generate insights'}></RadioSelect>
                        </RadioGroup>
                    </Grid>

                    {!isGeneratedInsight && (
                        <>
                            <Grid item xs={12} data-testid="custom-insight">
                                <Typography variant={'body1'} paddingTop={'2rem'}>
                                    Write the lesson, tip or insight you want to use in the email
                                </Typography>
                            </Grid>
                            <Grid container item xs={12} flexDirection="column">
                                <Typography variant={'caption'}>
                                    Write a helpful tip and make it as specific, uncommon, and helpful as possible.
                                </Typography>
                                <Typography variant={'caption'}>
                                    Limit the length to no longer than 7 sentences.
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    value={userInsight}
                                    inputProps={{'data-testid': 'user-input-insight'}}
                                    multiline
                                    fullWidth
                                    minRows={4}
                                    variant={'outlined'}
                                    onChange={(val) => {
                                        setUserInsight(val.target.value);
                                        localStorage.setItem(localStorageKeys.selectedInsight, val.target.value);
                                    }}
                                    sx={{
                                        paddingTop: '.5rem',
                                        paddingBottom: '.5rem',
                                        '& .MuiFilledInput-root': {
                                            backgroundColor: 'white'
                                        }
                                    }}></TextField>
                            </Grid>
                        </>
                    )}
                    {isGeneratedInsight && (
                        <Grid item xs={12} paddingTop={'2rem'}>
                            <Typography variant={'h6'}>Insights</Typography>
                        </Grid>
                    )}
                    {error && (
                        <Grid item xs={12} paddingTop={'2rem'}>
                            <Alert severity="warning">
                                <AlertTitle>We couldn't generate insights.</AlertTitle>
                                Because of a technical issue, we couldn't get an answer from the AI. Try refreshing
                                your page. If the issue continues, click the "Clear and Restart" button.
                            </Alert>
                        </Grid>
                    )}
                    {isGeneratedInsight && !loading && !error && (
                        <GeneratedResponses
                            generatedResponses={[firstInsight, secondInsight, thirdInsight, fourthInsight]}
                            selectionKey={localStorageKeys.selectedInsight}
                            setDidUserSelect={setDidUserSelect}></GeneratedResponses>
                    )}
                    {isGeneratedInsight && loading && !error && (
                        <Grid item xs={12} sx={{marginTop: '2rem'}}>
                            <CircularProgress/>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid container item>
                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        padding={isSmallScreen ? '1rem 1rem' : '1rem 5rem'}>
                        <Button
                            data-testid="reset-button"
                            onClick={reset}
                            variant="outlined"
                            sx={{minWidth: '4rem'}}>
                            CLEAR AND RESTART
                        </Button>
                        <Button
                            data-testid="next-button"
                            disabled={!isInsightSelected}
                            onClick={handleNextButton}
                            variant="contained">
                            NEXT
                        </Button>
                    </Box>
                </Paper>
            </Grid>
        </>
    );
};
