import { Grid, RadioGroup } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { GenericSelectionButton } from './GenericSelectionButton';

export const GeneratedResponses: React.FC<{
  generatedResponses: string[];
  selectionKey: string;
  setDidUserSelect: (didSelect: boolean) => void;
}> = ({ generatedResponses, selectionKey, setDidUserSelect }) => {
  const [currentIndex, setCurrentIndex] = useState('');

  return (
    <Grid container item xs={12} data-testid="generated-responses">
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="yes"
        name="radio-buttons-group"
        value={currentIndex}
        onChange={(event) => {
          setDidUserSelect(true);
          setCurrentIndex(event.target.value);
        }}>
        {generatedResponses.map(
          (subject, index) =>
            subject !== '' && (
              <GenericSelectionButton
                key={index}
                value={index.toString()}
                text={subject}
                selectionKey={selectionKey}
                isSelected={currentIndex === index.toString()}
              />
            )
        )}
      </RadioGroup>
    </Grid>
  );
};
