import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { commonColors } from '../commonColors';
import VerticalLinearStepper from '../components/Stepper';
import { generateSubjectLines } from '../services/networkRequests';
import { localStorageKeys } from '../localStorageKeys';
import { HeaderBar } from '../components/HeaderBar';
import { useNavigate } from 'react-router-dom';
import { parseResponseValue } from '../services/parseResponseValue';
import { GeneratedResponses } from '../components/GeneratedResponses';

export const Subject = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [firstSubject, setFirstSubject] = useState('');
  const [secondSubject, setSecondSubject] = useState('');
  const [thirdSubject, setThirdSubject] = useState('');
  const [fourthSubject, setFourthSubject] = useState('');
  const [fifthSubject, setFifthSubject] = useState('');
  const [sixthSubject, setSixthSubject] = useState('');
  const [seventhSubject, setSeventhSubject] = useState('');
  const [eighthSubject, setEighthSubject] = useState('');
  const [didUserSelect, setDidUserSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const setSubject = async () => {
    setLoading(true);
    const story = localStorage.getItem(localStorageKeys.selectedStory) || '';
    try {
      const responseReader = await generateSubjectLines(story);
      setLoading(false);
      if (responseReader === undefined) {
        console.error('Something went wrong when corresponding with the AI');
        setError(true);
        return;
      }
      let index = -1;
      while (true) {
        const { value, done } = await responseReader.read();
        if (done) {
          break;
        }

        try {
          index = parseResponseValue(index, value, [
            setFirstSubject,
            setSecondSubject,
            setThirdSubject,
            setFourthSubject,
            setFifthSubject,
            setSixthSubject,
            setSeventhSubject,
            setEighthSubject
          ]);
        } catch (error) {
          setError(true);
          setLoading(false);
        }
      }
    }
    catch (error) {
      console.error(error);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setSubject();
  }, []);

  const handleNextButton = () => {
    navigate('/confirmation');
  };

  const reset = () => {
    localStorage.clear();
    navigate('/describe');
  };

  return (
    <>
      <HeaderBar />
      <Grid
        container
        padding={isSmallScreen ? '1rem 0rem' : '2rem 5rem'}
        justifyContent={isSmallScreen ? 'center' : 'left-align'}
        bgcolor={commonColors.lightBlue}>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'stretch'
        }}>
        <Grid container item justifyContent="center" xs={12} sm={10} md={8}>
          <VerticalLinearStepper activeStep={2} />
        </Grid>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          sx={{
            backgroundColor: 'white',
            padding: '2rem',
            height: '60vh',
            overflowY: 'auto',
            marginTop: '1rem'
          }}>
          <Grid item xs={12}>
            <Typography variant={'h6'} sx={{ fontWeight: 'bold' }}>
              Pick a Subject
            </Typography>
          </Grid>
          <Grid item xs={12} paddingTop={'1rem'}>
            <Typography variant={'body1'}>
              We’ve generated a list of subject lines based on the story you selected. Select one.
            </Typography>
          </Grid>
          {loading && !error && (
            <Grid item xs={12} sx={{ marginTop: '2rem' }}>
              <CircularProgress />
            </Grid>
          )}
          {!loading && !error && (
            <GeneratedResponses
              generatedResponses={[
                firstSubject,
                secondSubject,
                thirdSubject,
                fourthSubject,
                fifthSubject,
                sixthSubject,
                seventhSubject,
                eighthSubject
              ]}
              selectionKey={localStorageKeys.selectedSubject}
              setDidUserSelect={setDidUserSelect}
            />
          )}
          {error && (
            <Grid item xs={12} paddingTop={'2rem'}>
              <Alert severity="warning">
                <AlertTitle>We couldn't generate subject lines.</AlertTitle>
                Because of a technical issue, we couldn't get an answer from the AI. Try refreshing
                your page. If the issue continues, click the "Clear and Restart" button.
              </Alert>
            </Grid>
          )}
        </Grid>
        <Grid container item>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              padding={isSmallScreen ? '1rem 1rem' : '1rem 5rem'}>
              <Button
                data-testid="reset-button"
                onClick={reset}
                variant="outlined"
                sx={{ minWidth: '4rem' }}>
                CLEAR AND RESTART
              </Button>
              <Button
                data-testid="next-button"
                disabled={!didUserSelect}
                onClick={handleNextButton}
                variant="contained">
                NEXT
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
