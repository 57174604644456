import * as React from 'react';
import { FormControlLabel, Radio } from '@mui/material';
import { commonColors } from '../commonColors';

export const GenericSelectionButton: React.FC<{
  text: string;
  value: string;
  selectionKey: string;
  isSelected: boolean;
}> = ({ text, value, selectionKey, isSelected }) => {
  return (
    <FormControlLabel
      data-testid={`${selectionKey}-button`}
      sx={{
        border: `1px solid ${isSelected ? commonColors.primaryBlue : 'rgba(0,0,0,0.23)'}`,
        borderRadius: '.25rem',
        padding: '.5rem',
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginLeft: 0,
        marginRight: '1rem',
        marginTop: '1rem',
        width: '100%'
      }}
      value={value}
      labelPlacement="end"
      control={<Radio sx={{
          '& .MuiSvgIcon-root': {
              marginTop: '-0.35rem'
          }
      }} />}
      label={text}
      onClick={() => {
        localStorage.setItem(selectionKey, text);
      }}
    />
  );
};
