import * as React from 'react';
import {Paper, AppBar, Toolbar, Typography} from "@mui/material";


export const HeaderBar: React.FC = () =>
    <Paper sx={{ flexGrow: 1 }}>
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    Email Story Wizard
                </Typography>
            </Toolbar>
        </AppBar>
    </Paper>
