import * as React from 'react';
import { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { commonColors } from '../commonColors';
import { useNavigate } from 'react-router-dom';
import { localStorageKeys } from '../localStorageKeys';
import { HeaderBar } from '../components/HeaderBar';

export const Confirmation = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Email Copied to Clipboard');

  const reset = () => {
    localStorage.clear();
    navigate('/describe');
  };

  const handleCopyClick = async () => {
    const textToCopy =
      'Subject:\n' +
      localStorage.getItem(localStorageKeys.selectedSubject) +
      '\n\nInsight:\n' +
      localStorage.getItem(localStorageKeys.selectedInsight) +
      '\n\nStory:\n' +
      localStorage.getItem(localStorageKeys.selectedStory);

    try {
      await navigator.clipboard.writeText(textToCopy);
      setOpenSnackbar(true);
      setSnackbarMessage('Email Copied to Clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      setSnackbarMessage('Error: Could not copy text to clipboard');
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );
  return (
    <>
      <HeaderBar />
      <Grid
        container
        padding={isSmallScreen ? '1rem 0rem' : '2rem 5rem'}
        justifyContent={isSmallScreen ? 'center' : 'left-align'}
        bgcolor={commonColors.lightBlue}>
      </Grid>
      <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'stretch'
        }}>
        <Grid
          item
          xs={12}
          sm={10}
          md={8}
          sx={{
            backgroundColor: 'white',
            padding: '2rem',
            height: '60vh',
            overflowY: 'auto',
            marginTop: '1rem'
          }}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Subject</Typography>
          </Grid>
          <Grid item xs={12} paddingTop={'1rem'}>
            <Typography variant={'body1'}>
              {localStorage.getItem(localStorageKeys.selectedSubject)}
            </Typography>
          </Grid>
          <Grid item xs={12} paddingTop={'2rem'}>
            <Typography variant={'h6'}>Insight</Typography>
          </Grid>
          <Grid item xs={12} paddingTop={'1rem'}>
            <Typography variant={'body1'}>
              {localStorage.getItem(localStorageKeys.selectedInsight)}
            </Typography>
          </Grid>
          <Grid item xs={12} paddingTop={'2rem'}>
            <Typography variant={'h6'}>Story</Typography>
          </Grid>
          <Grid item xs={12} paddingTop={'1rem'}>
            <Typography variant={'body1'}>
              {localStorage.getItem(localStorageKeys.selectedStory)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              padding={isSmallScreen ? '1rem 1rem' : '1rem 5rem'}>
              <Button
                data-testid="reset-button"
                onClick={reset}
                variant="outlined"
                >
                RESTART
              </Button>
              <Button
                data-testid="copy-button"
                sx={{ minWidth: '4rem' }}
                onClick={handleCopyClick}
                variant="contained">
                Copy to Clipboard
              </Button>
              <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackbarMessage}
                action={action}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
