export const localStorageKeys = {
    summary : "1.1",
    audience: "1.2",
    audienceGoals: "1.3",
    generatedInsights: "generatedInsights",
    selectedInsight: "selectedInsight",
    generatedStories: "generatedStories",
    selectedStory: "selectedStory",
    generatedSubject: "generatedSubjectLines",
    selectedSubject: "selectedSubjectLine"
}