export const parseResponseValue = (index: number, value: string, setters: Function[]) => {
  const splitText = value.split(/(?! {2})/g);

  for (let word of splitText) {
    if (word.includes('¶')) {
      index++;
      if (index >= setters.length) {
        console.error('AI returned too many ¶');
        throw new Error('AI returned too many ¶');
      }
    } else {
      if (index >= 0) {
        setters[index]((prev: string) => {
          return prev + word;
        });
      }
    }
  }
  return index;
};
