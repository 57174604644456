import * as React from 'react';
import {useState} from 'react';
import {
    Alert, AlertTitle,
    Box,
    Button, Chip, CircularProgress,
    Grid,
    Paper,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {reviseStory} from '../services/networkRequests';
import {localStorageKeys} from '../localStorageKeys';
import {useNavigate} from 'react-router-dom';
import {autopopulatedRevisionChoices} from "../autopopulated-revision-choices";

export const ReviewStory: React.FC = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [story, setStory] = useState(localStorage.getItem(localStorageKeys.selectedStory) || '');
    const [revision, setRevision] = useState('');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const handleRewriteButton = async () => {
        setError(false);
        setLoading(true);

        try {
            const responseReader = await reviseStory(story, revision);
            setLoading(false);
            setRevision('');
            if (responseReader === undefined) {
                console.error('Something went wrong when corresponding with the AI');
                setError(true);
                return;
            }
            setStory('');
            while (true) {
                const {value, done} = await responseReader.read();
                if (done) {
                    break;
                }

                setStory((prev) => prev + value);
            }
        } catch (error) {
            console.log(error);
            setError(true);
            setLoading(false);
        }
    };

    const handleContinueButton = () => {
        localStorage.setItem(localStorageKeys.selectedStory, story);
        navigate('/subject');
    };

    const reset = () => {
        localStorage.clear();
        navigate('/describe');
    };

    return (
        <>
            <Grid
                item
                xs={12}
                sm={10}
                md={8}
                sx={{
                    backgroundColor: 'white',
                    padding: '2rem',
                    height: '60vh',
                    overflowY: 'auto',
                    marginTop: '1rem'
                }}>
                <Grid item xs={12}>
                    <Typography variant={'h6'} sx={{fontWeight: 'bold'}}>
                        Review the Generated Story
                    </Typography>
                </Grid>
                {!loading && !error && (
                    <Grid item xs={12} marginTop={'1rem'}>
                        <Typography variant={'body1'}>{story}</Typography>
                    </Grid>
                )}
                {loading && !error && (
                    <Grid item xs={12} sx={{marginTop: '2rem'}}>
                        <CircularProgress/>
                    </Grid>
                )}
                {!loading && error && (
                    <Grid item xs={12} paddingTop={'2rem'}>
                        <Alert severity="warning">
                            <AlertTitle>We couldn't revise the story.</AlertTitle>
                            Because of a technical issue, we couldn't get an answer from the AI. Try refreshing
                            your page. If the issue continues, click the "Clear and Restart" button.
                        </Alert>
                    </Grid>
                )}
                <Grid item xs={12} paddingTop={'3rem'}>
                    <Typography variant={'h6'} sx={{fontWeight: 'bold'}}>
                        (Optional) Describe how you would like the story to be refined:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'caption'}>
                        Some common refinements. Use only if needed.
                    </Typography>
                </Grid>
                <Grid item container justifyContent={"flex-start"}>
                    {
                        autopopulatedRevisionChoices.map(i =>
                            <Grid item sx={{paddingRight: ".25rem", marginTop: ".25rem"}} key={`${i}`}>
                                <Chip label={i[0]} variant="filled" onClick={() => setRevision(i[1])}/>
                            </Grid>
                        )

                    }
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={revision}
                        inputProps={{'data-testid': 'revision'}}
                        multiline
                        fullWidth
                        minRows={4}
                        variant={'outlined'}
                        onChange={(val) => setRevision(val.target.value)}
                        sx={{
                            paddingTop: '.5rem',
                            paddingBottom: '.5rem',
                            '& .MuiFilledInput-root': {
                                backgroundColor: 'white'
                            }
                        }}></TextField>
                </Grid>
                {isSmallScreen && (
                    <Button
                        data-testid="rewrite-button"
                        onClick={handleRewriteButton}
                        variant="outlined"
                        sx={{minWidth: '4rem', marginRight: '1rem', marginBottom: '1rem'}}>
                        REWRITE THE INSIGHT
                    </Button>
                )}
            </Grid>
            <Grid container item>
                <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        padding={isSmallScreen ? '1rem 1rem' : '1rem 5rem'}>
                        <Button
                            data-testid="reset-button"
                            onClick={reset}
                            variant="outlined"
                            sx={{minWidth: '4rem'}}>
                            CLEAR AND RESTART
                        </Button>
                        <Box>
                            {!isSmallScreen && (
                                <Button
                                    data-testid="rewrite-button"
                                    onClick={handleRewriteButton}
                                    variant="outlined"
                                    sx={{minWidth: '4rem', marginRight: '1rem'}}>
                                    REWRITE THE STORY
                                </Button>
                            )}
                            <Button
                                data-testid="continue-button"
                                onClick={handleContinueButton}
                                variant="contained">
                                {isSmallScreen ? 'CONTINUE' : 'KEEP THIS STORY AND CONTINUE'}
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Grid>
        </>
    );
};
