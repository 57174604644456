import * as React from 'react';
import { commonColors } from '../commonColors';
import { FormControlLabel, Radio, useMediaQuery, useTheme } from '@mui/material';

export const RadioSelect: React.FC<{ value: string; label: string; isSelected: boolean }> = ({
  value,
  label,
  isSelected
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <FormControlLabel
      data-testid={`ai-insight-${value}`}
      sx={{
        border: `1px solid ${isSelected ? commonColors.primaryBlue : 'lightgrey'}`,
        borderRadius: '.25rem',
        padding: '.25rem',
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        width: `${isSmallScreen ? '100%' : '40%'}`,
        display: 'flex',
        justifyContent: 'flex-start',
        marginLeft: 0,
        marginRight: '1rem',
        marginBottom: '0.5rem'
      }}
      value={value}
      labelPlacement="end"
      control={<Radio />}
      label={label}
    />
  );
};
