import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


const steps = [
 'DESCRIBE',
 'INSIGHTS',
 'STORY',
 'SUBJECT',
];

export default function HorizontalLinearStepper({ activeStep }: { activeStep: number }) {

  return (
    <Box sx={{ width: '80%' }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => (
          <Step key={step}>
            <StepLabel >{step}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
